import { data } from './../../../../shared/data/smart-data-table';
import { Component, OnInit } from "@angular/core";
import { UserService } from "app/core/http";
import { ActivatedRoute, Params } from "@angular/router";
import { OtpService } from 'app/core/http/data-services/otp.service';

@Component({
  selector: "app-allocate-otp",
  templateUrl: "./allocate-otp.component.html",
  styleUrls: ["./allocate-otp.component.scss"],
})
export class AllocateOtpComponent implements OnInit {
  htmlImage: any;
  userId: any;
  constructor(
    //private userService: UserService,
    private otpService : OtpService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    
    this.fetchData()
  }

  fetchData() {
    
    this.route.params.subscribe((params: Params) => {
      this.userId = params["id"];
    });
    this.otpService.allocateOTP(this.userId).subscribe(data=>{
      this.htmlImage = data.image;
      //console.log(data,99);
    });

    // this.userService.allocateOTP(this.userId).subscribe((data) => {
    //   console.log(data,33);
    //   this.htmlImage = data.image;
    // });
  }

 

}
