export const RoleNames = {
    Sale: 6,
    Manager: 7,
    Admin: 8,
    Coordinate: 9,
    /// <summary>
    /// Quyền xem của sale supervisor
    /// </summary>
    SaleSupervisorView: 10,
    
    CustomerCarer: 11,
    AfterSale: 12,
    /// <summary>
    /// Quyền sale supervisor editor
    /// </summary>
    SaleSupervisorEditor: 13,
    ListingAcquistion: 14,
    MediaSale: 15
};

export class Role {
    public name: string;
    public id: Number;

    constructor(id: Number, name: string) {
        this.name = name;
        this.id = id;
    }
}

export const ListRoles = [
    new Role(6, "Sale"),
    new Role(7, "Manager"),
    new Role(8, "Admin"),
    new Role(9, "Coordinate"),
    new Role(10, "Sale Leader"),
    new Role(11, "Customer carer"),
    new Role(12, "After-sale"),
    new Role(13, "Editor sale supervisor"),
    new Role(14, "Listing Acquistion"),
    new Role(15, "Media Sale"),
];

export const SaleRoleIds = [
   RoleNames.Sale,
   RoleNames.Manager,
   RoleNames.Admin,
   RoleNames.Coordinate,
   RoleNames.SaleSupervisorView,
   RoleNames.CustomerCarer,
   RoleNames.SaleSupervisorEditor,
   RoleNames.MediaSale,
   RoleNames.ListingAcquistion,
   RoleNames.AfterSale
]

export const PostingAssistantRoleIds = [
    RoleNames.AfterSale
]

export const RoleUtils = {
    isManager(roleId: number) {
        var managerRoles = [RoleNames.Admin, RoleNames.Manager, RoleNames.SaleSupervisorEditor, RoleNames.SaleSupervisorView];
        return managerRoles.includes(roleId);
    }
}