// The file contents for the current environment will overwrite these during build.
import { filter } from 'rxjs/operators';
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    api: {
        //baseUrl: "https://test.web.bcrm.indo.dvg-lc.com",
        baseUrl: "",
        endpoints: {
            customer: {
                filter: "api/customers",
                create: "api/customers",
                update: "api/customers",
                patch: "api/customers",
                getByAccountIds: "api/customers/details_report",
                assigneeMultiple: "api/customers/assignee_multiple",
                returnMultiple: "api/customers/return_multiple",
                blacklist: (customerId) => {
                    return `api/customers/${customerId}/blacklist`;
                },
                unblacklist: (customerId) => {
                    return `api/customers/${customerId}/unblacklist`;
                },
                addContact: (customerId) => {
                    return `api/customers/${customerId}/contacts`;
                },
                addNote: (customerId) => {
                    return `api/customers/${customerId}/notes`;
                },
                getUploadToken: (customerId) => {
                    return `api/customers/${customerId}/upload_token`;
                },
                resetPassword: (customerId) => {
                    return `api/customers/${customerId}/reset_password`;
                },
                assignee: (customerId) => {
                    return `api/customers/${customerId}/assignee`;

                },
                receive: (customerId) => {
                    return `api/customers/${customerId}/reception`;

                },

                // assigneeMultiple: (customerIds) =>{
                //     return `api/customers/assignee_multiple`
                // }
              
               

             
            },
            companies: {
                getAllCompanies:"api/companies",
                filter: "api/companies/filter",
                create: "api/companies",
                edit: (Id) => {
                    return `api/companies/${Id}`;
                },
                delete :(Id) =>{
                    return `api/companies/${Id}`;
                }

            },
            payments: {
                delete: "api/payments",
                filter: "api/payments",
                export: "api/payments/export",
                filterBalance: "api/payments/balances",
                create: "api/payments",
                path: "api/payments",
                createAction: "api/payments/create_action",
                updatePaymentDetails: (id) => {
                    return `api/payments/${id}/invoices`
                },
                update: "api/payments",
                findCustomer: "api/payments/customers",
                getPromotionCode: (customerId) => {
                    return `api/payments/promotion-code/${customerId}`;
                },
                getPakageById: (id) => {
                    return `api/payments/packages/${id}`;
                },
                approve: (paymentId) => {
                    return `api/payments/${paymentId}/receipt`
                },
                reject: (paymentId) => {
                    return `api/payments/${paymentId}/rejection`
                },
                progress: (paymentId) => {
                    return `api/payments/${paymentId}/progress`
                },
                assign: (paymentId) => {
                    return `api/payments/${paymentId}/sales`
                }
            },
            user: {
                filter: "api/users/filter",
                getAllUser: "api/users",
                getUserReport:'api/users/filterUserReport',
                create: "api/users",
                getAllLeader: "api/users/leaders",
                getLeaderAndCoordinate:"api/users/leaderAndCoordinates",
                getById: (userId) => {
                    return `api/users/${userId}`;
                },
                update: (userId) => {
                    return `api/users/${userId}`;
                },
                blockUser: (userId) => {
                    return `api/users/${userId}/blocks`;
                },
                unblockUser: (userId) => {
                    return `api/users/${userId}/blocks`;
                },
                hiddenReportUser: (userId) => {
                    return `api/users/${userId}/reports`;
                },
                openReportUser: (userId) => {
                    return `api/users/${userId}/reports`;
                },
             
                listPermission: (userId) => {
                    return `api/users/${userId}/permissions`;
                },
                createPermission: (userId) => {
                    return `api/users/${userId}/permissions`;
                },
                deletePermission: (userId) => {
                    return `api/users/${userId}/permissions`;
                },
                getListUserByGroupId:(groupId)=>{
                    return `api/users/${groupId}/group`;
                },
                changePassword: "api/users/passwords",

            },
            profiles: {
                path: "api/profiles",
                changeProfile: "api/profiles",
                changeAvatar: "api/profiles/avatars",
                changePassword: "api/profiles/passwords",

                edit: (userId) => {
                    return `api/profiles/${userId}`;
                },

            },
            region: {
                filterRegion: "api/regions",
                filterCity: "api/cities",
                filterDistrict: "api/districts",
                filterCompanies: "api/companies",
                filterCommom: "api/common"
            },
            userGroup: {
                filter: "api/user_groups/filters",
                allGroup: "api/user_groups",
                create: "api/user_groups",
                edit: "api/user_groups",
                getById: (id) => {
                    return `api/user_groups/${id}`;
                },
            },
            upload: "api/tokens",
            report: {
                adminAccounts: "api/reports/admin_accounts",           
                adminAccountsDetails:"api/reports/admin_accounts/details",
                customerCares:"api/reports/customer_cares",
                sale: "api/reports/sale",
                adminWorking : "api/reports/admin_workings",
                postingAssistant: "api/reports/posting_assistant",
                retentionRate: "api/reports/retention_rates",
                getCustomerByType: "api/reports/retention_rates/details_report",
                businessResult:"api/reports/business_reports",
                returningRate: "api/reports/returning_rates",
                customerActivityByDate: "api/reports/customer_activity_by_date",
                customerInvidualStatistics: "api/reports/customer_invidual_statistics",
                bumpChart:"api/reports/bump_charts",
                bumpChartPaiDup:"api/reports/bump_charts/paidup_promotions_table",
                exportBumpChartPaiDup:"api/reports/bump_charts/export",
                export:"api/reports/business_reports/export",
                saleRevenue: (userId) => {
                    return `api/reports/revenue_by_sales/${userId}`;
                },
                customerRechargeLogs: (customerId) => {
                    return `api/reports/customer_recharge_logs?customerId=${customerId}`;
                },
                customerCaresDetail :(userId)=>{
                    return `api/reports/customer_cares/${userId}`;
                }
            },
            lead: {
                filter: "api/leads",
                receive: (leadId) => {
                    return `api/leads/${leadId}/receive`;
                },
                getById: (leadId) => {
                    return `api/leads/${leadId}`;
                },
                confirmContactSuccessFully: (leadId) => {
                    return `api/leads/${leadId}/success_contacts`;
                },
                recordRejectedContact: (leadId, careHistoryId) => {
                    return `api/leads/${leadId}/${careHistoryId}/rejected_contacts`;
                },
                hideLead: (leadId) => {
                    return `api/leads/${leadId}/hide`;
                },
                unhideLead: (leadId) => {
                    return `api/leads/${leadId}/unhide`;
                },
                getAffectedCareLog: (leadId) => {
                    return `api/leads/${leadId}/care_logs/affected_item`;
                },
                updateCareLogNote: (leadId, careLogId) => {
                    return `api/leads/${leadId}/care_logs/${careLogId}/note`;
                },
                returnCare:(leadId) =>{
                   return `api/leads/${leadId}/return`
                },
            },

            mediaPrice: {
                videoPrice: "api/video_prices",
                contentPrice: "api/content_prices",
                bannerPrice: "api/banner_prices",
            },
            kpi: {
                create: (userId) => {
                    return `api/user_kpis/${userId}/kpis`
                },
                update: (userId, month) => {
                    return `api/user_kpis/${userId}/kpis/${month}`
                },
                get: (userId, month) => {
                    return `api/user_kpis/${userId}/kpis/${month}`
                }

            },

            otp:{
                allocateOtp: (userId) => {
                    return `api/OTP/${userId}/otps`;
                }
            }
        },
        apiClient: {
            //baseUrl: "https://test.web.bcrm.indo.dvg-lc.com",
            baseUrl: "",
            endpoints: {
                auth: {
                    login: "api/authentication",

                    // refresh: "api/authentication/refresh_token",
                },
                tokens: {

                    refresh: "api/authentication/refresh_token",
                }
            },
        },
        fileStorage: {
            uploadBaseUrl: "https://upload.chobrod.com/UploadHandler.php",
            fileBaseUrl: "https://file.chobrod.com",
        },
        localStorageKey: "BCRM",
    },
};
