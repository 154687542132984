//chưa dùng đến
export const customerPostCountRanges = {};

export class Enumeration {
    public name: string;
    public id: Number;
    constructor(id: Number, name: string) {
        this.name = name;
        this.id = id;
    }
}

export class CustomerType {
    public name: string;
    public id: Number;
    public minPostCount: Number;
    public maxPostCount: Number;
    constructor(id: Number, name: string, minPostCount: Number, maxPostCount: Number) {
        this.name = name;
        this.id = id;
        this.minPostCount = minPostCount;
        this.maxPostCount = maxPostCount;
    }
}
export const CustomerTypes = [
    new CustomerType(0, "Brand", 0, 10),
    new CustomerType(1, "Broker", 10, 50),
    new CustomerType(2, "Owner", 51, null),
    new CustomerType(3, "Buyer", 51, null),
];

export class CompanyType {
    public name: string;
    public id: Number;
    constructor(id: Number, name: string) {
        this.name = name;
        this.id = id;
    }
}
export const CompanyTypes = [
    new CompanyType(1, "Car Brand"),
    new CompanyType(2, "Accessories"),
    new CompanyType(3, "Finance"),
    new CompanyType(4, "Car Insurance"),
];



export class CustomerContactedStatus extends Enumeration {
    constructor(id: Number, name: string) {
        super(id, name);
    }
}
export const CustomerContactStatuses = {
    NoContact: new CustomerContactedStatus(0, "No contact"),
    Accepted: new CustomerContactedStatus(1, "Accepted"),
    Rejected: new CustomerContactedStatus(2, "Rejected"),
};

export const CustomerContactStatusNew = {
    NotCalled: new CustomerContactedStatus(0, "NotCalled"),
    Success: new CustomerContactedStatus(2, "Success"),
    Reject: new CustomerContactedStatus(1, "Reject"),
};




export const SystemCode = {
    /// <summary>
    /// Thành công
    /// </summary>
    Success: 1,
    /// <summary>
    /// Lỗi
    /// </summary>
    Error: 0,
    /// <summary>
    /// Lỗi báo đối tượng được kiểm tra là đã tồn tại
    /// </summary>
    ErrorExist: 2,
    /// <summary>
    /// Không có dữ liệu
    /// </summary>
    DataNull: 3,
    /// <summary>
    /// Tham số không đúng
    /// </summary>
    ErrorParam: 4,
    /// <summary>
    /// Không có quyền thực thi
    /// </summary>
    NotPermitted: 5,
    /// <summary>
    /// Không hợp lệ
    /// </summary>
    NotValid: 6,
    /// <summary>
    /// Đã bị khóa
    /// </summary>
    Locked: 7,
    /// <summary>
    /// Lỗi liên quan đến các vấn đề vượt quá giới hạn
    /// </summary>
    Overflow: 8,
    /// <summary>
    /// Lỗi liên quan đến các vấn đề kết nối
    /// </summary>
    ErrorConnect: 9,
    /// <summary>
    /// Lỗi liên quan đến các vấn đề đồng bộ
    /// </summary>
    Sync: 10,
};

export { HideReason, HideReasons } from './hide-reason';
export { Role, RoleNames, ListRoles } from './role';
export { RevenueType, revenueTypes } from './RevenueType';
export { SalePackage, salePackages } from './SalePackage';

