import { RoleNames } from "../../statics/role";

export class LoggedInUser {
  constructor(
    accessToken: string,
    userId: number,
    userName: string,
    roleIds: string,
    roleNames: string,
    groupId: number

  ) {
    (this.accessToken = accessToken),
      (this.userId = userId),
      (this.userName = userName),
      (this.roleIds = roleIds),
      (this.roleNames = roleNames),
      (this.groupId = groupId);
  }
  public accessToken: string;
  public userId: number;
  public userName: string;
  public roleIds: string;
  public roleNames: string;
  public groupId: number;

    hasRole(roleId) {
        let roles = this.roleIds.split(',');
        return roles.includes(roleId.toString());
    }
    isOnlyLA() {
        let roles = this.roleIds.split(',').map(r => { return Number(r) });
        if (!roles.includes(RoleNames.ListingAcquistion))
            return false;
        if (this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager) || this.hasRole(RoleNames.SaleSupervisorEditor))
            return false;

        return true;
    }
    canCreateCustomer() {
        if (this.hasRole(RoleNames.Admin)
            || this.hasRole(RoleNames.Manager)
            || this.hasRole(RoleNames.SaleSupervisorView)
            || this.hasRole(RoleNames.Sale)
            || this.hasRole(RoleNames.MediaSale)
            || this.hasRole(RoleNames.ListingAcquistion)
            || this.hasRole(RoleNames.CustomerCarer)
        )
            return true;
        return false;
    }

    canViewActionInCus(){
        if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager))
        return true;
    return false;
    }
    

   canViewActionCustomer(){
       if(this.hasRole(RoleNames.Sale)

       )
       return true;

       return false;
   }


   canCreatePayment(){
       if(!this.hasRole(RoleNames.Coordinate))
       return true;

       return false;
   }
   canExportPayment(){
    if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager) ||  this.hasRole(RoleNames.SaleSupervisorView))
       return true;

       return false;
   }

   IsApproveOrReject(){
       if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Coordinate) || this.hasRole(RoleNames.Manager))
         return true;
         return false;
   }

   IsHiddenPermission(){
       if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager))
       return true;
       return false;
   }


  CanViewActionCustomer(){
      if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager) || this.hasRole(RoleNames.SaleSupervisorView) || this.hasRole(RoleNames.Sale))
      return true;
      return false;
  }


  IsEditable(){
      if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager))
      return true;
       return false;
  }

  IsBlockable(){
    if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager))
    return true;
     return false;
  }


  IsAssigningTo(){
    if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager) || this.hasRole(RoleNames.SaleSupervisorView))
    return true;
     return false;
  }

  IsBlacklisted(){
    if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager))
    return true;
     return false;
  }


  IsReceived(){
      if(this.hasRole(RoleNames.Admin) || this.hasRole(RoleNames.Manager) || this.hasRole(RoleNames.SaleSupervisorView) || this.hasRole(RoleNames.Sale) )
      return true;
       return false;
  }
  




//    var isCanSale = current.HasRole(RoleSystem.Admin, RoleSystem.Manager, RoleSystem.TeamLeader, RoleSystem.Sale);




//    public bool IsHiddenPermission(bool isManager)
//    {
//        return (isManager && !this.IsHidden ? true : false);
//    }
//    public bool IsUnHiddenPermission(bool isManager)
//    {
//        return (isManager && this.IsHidden ? true : false);
//    }




//    public bool IsApproveOrReject(UserAuthenticateModel currUser)
//    {
//        if (this.Status != (short)PaymentStatus.Pending)
//        {
//            return false;
//        }
//        if (currUser.HasRole(RoleSystem.Admin, RoleSystem.Coordinate, RoleSystem.Manager))
//        {
//            return true;
//        }
//        return false;
//    }

//    public bool IsRemove(UserAuthenticateModel currUser)
//    {
//        if (this.Status != (short)PaymentStatus.Pending)
//        {
//            return false;
//        }
//        if (currUser.UserName.Equals(this.CreatedBy))
//        {
//            return true;
//        }
//        return false;
//    }








   canViewActionPayment(){
       if(this.hasRole(RoleNames.SaleSupervisorView) || this.hasRole(RoleNames.CustomerCarer)
        || this.hasRole(RoleNames.Manager) || this.hasRole(RoleNames.Sale)){
           return true;
       }
        return false;
       


      
   }


}
