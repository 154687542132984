import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { LoggedInUser } from "./models/LoggedInUser";
import * as jwt_decode from "jwt-decode";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AuthService {
  token: string;

  constructor(private http: HttpClient) { }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(requestBodyModel) {
    return this.http
      .post(
        `${environment.api.apiClient.baseUrl}/${environment.api.apiClient.endpoints.auth.login}`,
        requestBodyModel
      )
      .pipe(
        map((data: { accessToken: string }) => {
          if (data && data.accessToken) {
            localStorage.removeItem(`${environment.api.localStorageKey}`);
            localStorage.setItem(
              `${environment.api.localStorageKey}`,
              data.accessToken
            );
          }
        })
      );
  }

  refreshToken() {
    return this.http
      .get(
        `${environment.api.apiClient.baseUrl}/${environment.api.apiClient.endpoints.tokens.refresh}`
      )
      .pipe(
        map((data: { accessToken: string }) => {
          if (data && data.accessToken) {
            localStorage.removeItem(`${environment.api.localStorageKey}`);
            localStorage.setItem(
              `${environment.api.localStorageKey}`,
              data.accessToken
            );
          }
        })
      );
  }

  isUserAuthenticated(): boolean {
    const user = localStorage.getItem(`${environment.api.localStorageKey}`);
    if (user != null) {
      return true;
    } else {
      return false;
    }
  }
  getLoggedInUser(): LoggedInUser {
    let user: LoggedInUser;
    if (this.isUserAuthenticated()) {
      const userData = jwt_decode(
        localStorage.getItem(`${environment.api.localStorageKey}`)
      );
      user = new LoggedInUser(
        localStorage.getItem(`${environment.api.localStorageKey}`),
        userData.UserId,
        userData.UserName,
        userData.RoleIds,
        userData.RoleName,
        userData.GroupId
      );
    } else {
      user = null;
    }
    return user;
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);
    if (decoded.exp === undefined) return null;
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  getToken(): string {
    return localStorage.getItem(`${environment.api.localStorageKey}`);
  }

  isTokenExpired(token?: string): boolean {
    if (!token) token = this.getToken();
    if (!token) return true;

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }

  logout() {
    localStorage.removeItem(`${environment.api.localStorageKey}`);
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token
    return true;
  }
}
