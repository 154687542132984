import { OnInit, Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'custom-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class CustomPaginationComponent implements OnInit {
    math = Math;
    @Input() page: number;
    @Input() limit: number;
    @Input() count: number;
    //Math.ceil(total_items/limit);
    //totalPage:Number;
  
                
    @Output()
    pageChanged: EventEmitter<number> = new EventEmitter<number>();

    @Output()
    limitChanged: EventEmitter<number> = new EventEmitter<number>();

    ngOnInit(): void {

        
    }
    changePage(page) {
        this.pageChanged.emit(page);
    }
    changeLimit(event) {
        this.limitChanged.emit(event.target.value);
    }
}