import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Params } from '@angular/router';

@Injectable({ providedIn: "root" })
export class ApiClient {

    constructor(private http: HttpClient) {

    }
    get<T>(path: string, paramObject: any = null): Observable<T> {
        var params = new HttpParams();

        if (paramObject != null && paramObject != undefined) {
            for (let key in paramObject) {
                var value = paramObject[key];
                if (value != null && value != undefined)
                    params = params.set(key, value);
            }
        }

        var observable = this.http.get<T>(
            `${environment.api.baseUrl}/${path}`,
            {
                params,
                headers: new HttpHeaders().set("Authorization", `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySWQiOiIxMDAiLCJVc2VyTmFtZSI6ImxlYmF0dWFuYW5oIiwiTmFtZSI6IiIsIlJvbGVOYW1lIjoiQWRtaW4iLCJuYmYiOjE1ODg2NDgwNTksImV4cCI6MTU4OTI1Mjg1OSwiaWF0IjoxNTg4NjQ4MDU5fQ.8VPKBkCarESlAfXwbLnQDbiqfi5gwoRAWyAIQhBO7LA`)
            }
        )
        return observable;
    }
    post<T>(path: string, body: any = null, paramObject: any = null): Observable<T> {
        var params = new HttpParams();

        if (paramObject != null && paramObject != undefined) {
            for (let key in paramObject) {
                var value = paramObject[key];
                if (value)
                    params = params.set(key, value);
            }
        }

        var observable = this.http.post<T>(
            `${environment.api.baseUrl}/${path}`,
            body,
            {
                params
            }
        )
        return observable;
    }
    put<T>(path: string, body: any = null, paramObject: any = null): Observable<T> {
        var params = new HttpParams();

        if (paramObject != null && paramObject != undefined) {
            for (let key in paramObject) {
                var value = paramObject[key];
                if (value)
                    params = params.set(key, value);
            }
        }

        var observable = this.http.put<T>(
            `${environment.api.baseUrl}/${path}`,
            body,
            {
                params

            }
        )
        return observable;
    }
    delete<T>(path: string, body: any = null, paramObject: any = null): Observable<T> {
        var params = new HttpParams();

        if (paramObject != null && paramObject != undefined) {
            for (let key in paramObject) {
                var value = paramObject[key];
                if (value)
                    params = params.set(key, value);
            }
        }
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            }), body,
            params

        }

        var observable = this.http.delete<T>(`${environment.api.baseUrl}/${path}`, options)
        return observable;

    }
    path<T>(path: string, body: any = null, paramObject: any = null): Observable<T> {
        var params = new HttpParams();

        if (paramObject != null && paramObject != undefined) {
            for (let key in paramObject) {
                var value = paramObject[key];
                if (value)
                    params = params.set(key, value);
            }
        }
        var observable = this.http.patch<T>(`${environment.api.baseUrl}/${path}`, body, { params });
        return observable;
    }
}




