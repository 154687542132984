import { Routes, RouterModule } from "@angular/router";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "admin/customers",
    loadChildren: () =>
      import("../../customer/customer.module").then((m) => m.CustomerModule),
  },
  {
    path: "admin/reports",
    loadChildren: () =>
      import("../../report/report.module").then((m) => m.ReportModule),
  },
  {
    path: "admin/payments",
    loadChildren: () =>
      import("../../payments/payments.module").then((m) => m.PaymentsModule),
  },
  {
    path: "admin/leads",
    loadChildren: () =>
      import("../../lead/lead.module").then((m) => m.LeadModule),
  },
  {
    path: "admin/companies",
    loadChildren: () =>
      import("../../companies/companies.module").then((m) => m.CompanyModule),
  },
  {
    path: "admin/users",
    loadChildren: () =>
      import("../../user/user.module").then((m) => m.UserModule),
  },
  {
    path: "admin/user-groups",
    loadChildren: () =>
      import("../../user-group/user-group.module").then(
        (m) => m.UserGroupModule
      ),
  },
  {
    path: "admin/profile",
    loadChildren: () =>
      import("../../user-profile/user-profile.module").then(
        (m) => m.UserProfileModule
      ),
  },
  {
    path: 'admin/media_prices',
    loadChildren: () => import('../../media-price/media-price.module').then(m => m.MediaPriceModule)
  }
];
