import { Enumeration } from './enumeration';

export class HideReason extends Enumeration {
    constructor(id: Number, name: string) {
        super(id, name);
    }
}

export const HideReasons = [
    new HideReason(1, "Account is already existing!"),
    new HideReason(2, "The owner no longer works in the showroom"),
    new HideReason(3, "The owner doesn't sell cars anymore"),
    new HideReason(4, "Phone number can not be contacted"),
    new HideReason(5, "Phone does not exist"),
    new HideReason(6, "Other")
]

