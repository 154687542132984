import { Injectable } from "@angular/core";
import { ApiClient } from "./api.service";
import { environment } from "../../../../environments/environment";
import { UserConstant } from 'app/user/user.constant';

@Injectable({ providedIn: "root" })
export class OtpService {
  constructor(private http: ApiClient) { }

  allocateOTP(userId) {
    var observable = this.http.post<any>(
      `${environment.api.endpoints.otp.allocateOtp(userId)}`
    );
    return observable;
  }

  
}
