import { Enumeration } from './enumeration';

export class SalePackage extends Enumeration {

}

export const salePackages = {
    Free: new SalePackage(1, 'Free'),
    Boosting: new SalePackage(2, 'Boosting'),
    Eco: new SalePackage(3, 'Eco'),
    Premium: new SalePackage(4, 'Premium'),
    Ultimate1:new SalePackage(5, 'Ultimate 1'),
    Ultimate2:new SalePackage(6, 'Ultimate 2'),
    Ultimate3:new SalePackage(7, 'Ultimate 3'),
    Ultimate4: new SalePackage(8, 'Ultimate 4')
}
    
