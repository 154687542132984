import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'month-picker',
    templateUrl: 'month-picker.component.html',
    styleUrls: ['month-picker.component.scss'],
})
export class MonthPickerComponent implements OnInit {
    months: Array<any>;
    @Input("minYear") minYear: Number;
    @Input("maxYear") maxYear: Number;
    @Input("selectedMonth") selectedMonth: Number;
    @Input("selectedYear") selectedYear: Number;

    years: Array<Number>;
    currentYear: Number = new Date().getFullYear();

    @Output()
    monthSelected: EventEmitter<Number> = new EventEmitter<Number>();

    @Output()
    yearSelected: EventEmitter<Number> = new EventEmitter<Number>();

    constructor() {
        
    }
    ngOnInit(): void {
        this.years = [];

        for (let i: any = this.minYear; i <= this.maxYear; i++) {
            this.years.push(i);
        };
        this.months = [
            { id: 1, name: 'January' },
            { id: 2, name: 'February' },
            { id: 3, name: 'March' },
            { id: 4, name: 'April' },
            { id: 5, name: 'May' },
            { id: 6, name: 'June' },
            { id: 7, name: 'July' },
            { id: 8, name: 'August' },
            { id: 9, name: 'September' },
            { id: 10, name: 'October' },
            { id: 11, name: 'November' },
            { id: 12, name: 'December' }
        ];
    }

    selectMonth(month) {
        this.monthSelected.emit(month);
    }
    selectYear(year) {
        this.yearSelected.emit(year);
    }
}