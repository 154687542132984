import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthService } from "../../auth/auth.service";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(`${environment.api.localStorageKey}`);
    let clone: HttpRequest<any>;
    if (token && req.url != "https://upload.cintamobil.com/UploadHandler.php") {
      if (this.authService.isTokenExpired(token)) {
        this.authService.refreshToken();
      }
      clone = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
      return next.handle(clone).pipe(
        catchError((response) => {
          if (response.status === 401) {
            this.router.navigate(["/admin/pages/login"]);
          } 
          // else if (response.status === 500) {
          //   this.router.navigate(["/admin/pages/error-server"]);
          // }
          return throwError(response.error || response);
        })
      );
    } else {
      return next.handle(req).pipe(
        catchError((response) => {
          if (response.status === 401) {
            this.router.navigate(["/admin/pages/login"]);
          } 
          // else if (response.status === 500) {
          //   this.router.navigate(["/admin/pages/error-server"]);
          // }
          return throwError(response.error || response);
        })
      );
    }
  }
}
