import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { environment } from "environments/environment";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private authService:AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isTokenExpired()) {
      return true;
    }
    if (localStorage.getItem(`${environment.api.localStorageKey}`)) {
      return true;
    } else {
      this.router.navigate(["/admin/pages/login"], {
        queryParams: {
          returnUrl: state.url,
        },
      });
      return false;
    }
  }
}
